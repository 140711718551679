import mainRefs from "@/__main__/refs.mjs";
import { Wrapper } from "@/feature-docs/DocsWrapper.jsx";
import docsRoutes from "@/feature-docs/routes.mjs";
import routes from "@/routes/routes.mjs";

const originals = {};
export function setup() {
  originals.NonAppNavigation = mainRefs.components.NonAppNavigation;
  mainRefs.components.NonAppNavigation = Wrapper;
  routes.push(...docsRoutes);
}

export function teardown() {
  mainRefs.components.NonAppNavigation = originals.NonAppNavigation;
  for (const route of docsRoutes) {
    const i = routes.indexOf(route);
    routes.splice(i, 1);
  }
}
