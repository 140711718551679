/**
 * DO NOT edit this page to add a new doc page. Add it to pages.mts.
 */
import pages from "@/feature-docs/pages.mjs";

const docsRoutes = pages.flat().map((p) => ({
  path: p.path,
  component: p.component,
}));

docsRoutes.unshift({
  path: "/docs",
  redirect: "/docs/docs-home",
});

export default docsRoutes;
