import React from "react";

import { Code } from "@/feature-docs/DocsComponents.jsx";
import * as Styled from "@/feature-docs/DocsWrapper.style.jsx";
import pages from "@/feature-docs/pages.mjs";
import BlitzIcon from "@/inline-assets/blitz.svg";
import BlitzWordmark from "@/inline-assets/wordmark.svg";
import { useTransientRoute } from "@/util/router-hooks.mjs";

const defaultComponents = { code: Code };
export function Wrapper({ children }) {
  const route = useTransientRoute();
  if (!route.currentPath?.startsWith("/docs")) return children;

  const currentPage = pages
    .flat()
    .find(({ path }) => path === route.currentPath);
  if (!currentPage) return null;
  return (
    <Styled.Container>
      <nav className="sidebar">
        <ul className="nav-list">
          <li>
            <a href="/docs" className="home-link">
              <BlitzIcon className="logo" />
              <BlitzWordmark className="wordmark" />
            </a>
          </li>
          <NavList pages={pages} currentPage={currentPage} />
        </ul>
      </nav>
      <div className="content-wrapper">
        <div className="header">
          <h1>{currentPage.title}</h1>
          <div role="doc-subtitle" className="subtitle">
            {currentPage.folder}
          </div>
        </div>
        <div className="content">
          {React.cloneElement(React.Children.only(children), {
            components: { ...defaultComponents, ...currentPage.docsFor },
          })}
        </div>
      </div>
    </Styled.Container>
  );
}

const NavList = ({ pages, currentPage }) =>
  pages.map((p, i) => (
    <li key={i}>
      {Array.isArray(p) ? (
        <Folder
          isActive={currentPage.folder === p[0].folder}
          title={p[0].folder}
        >
          <NavList pages={p} currentPage={currentPage} />
        </Folder>
      ) : (
        <NavItem isActive={currentPage.path === p.path} {...p} />
      )}
    </li>
  ));

const NavItem = ({ isActive, path, title }) => (
  <a href={path} data-active={isActive} className="nav-item">
    {title}
  </a>
);

const Folder = ({ isActive, title, children }) => (
  <ul className="folder">
    <li>
      <span className="nav-item" data-active={isActive}>
        {title}
      </span>
    </li>
    {children}
  </ul>
);
